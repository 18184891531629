<template>
  <div class="reprint-article-container">
    <header class="header">
      <van-nav-bar
          title="转载视频"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
      </van-nav-bar>
    </header>
    <article class="article">
      <div class="reprint">
        <span>视频地址</span>
        <div class="url-info">
          <van-field
              v-model="reprintUrl"
              autosize
              placeholder="请输入你要转载的视频地址"
              show-word-limit
          />
          <div style="font-size: 14px;width: 50px;color: #289fff;">
            <span @click="handleGenerate">解析</span>
          </div>
        </div>
        <div class="supper-platform">支持平台: <span @click="handleOpenBrowser">抖音</span></div>
      </div>
      <div class="article_info" v-if="showFinish">
<!--      <div class="article_info">-->
        <span>视频标题</span>
        <van-field
            v-model="video.title"
            rows="2"
            autosize
            type="textarea"
            placeholder="请输入你要添加的内容"
            show-word-limit
        />
        <span>视频封面</span>
        <div class="article_img">
          <image-upload
              v-if="loading"
              :busiId="vdId"
              :busiCat="busi_Cat"
              :groupId="group_Id_index"
          ></image-upload>
        </div>
      </div>
      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom v-if="showFinish">
          <van-grid-item
          ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleSaveArticle"
          >完成</van-button
          ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
  </div>
</template>
<script>
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
// import {openExternalBrowser} from "../../utils/commonUtil"

import {parseDouYinVideoUrl,addVideoByParseUrl} from "../../api/video";

import { Toast } from 'vant';
export default {
  components: {
    ImageUpload,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      vdId:'',
      showFinish:false,
      reprintUrl:'',
      showInCard:false,
      loading: true,
      busi_Cat: "video",
      group_Id_index: "videoIndexPic",
      isRealDeleteImg: false,
      newBusiId: "",
      baseImg:'',
      video: {
        title: "", //标题
      },
    });
    const handleOpenBrowser=()=>{
      // let url = 'https://weixin.sogou.com/'
      // openExternalBrowser(url)
    }
    const handleSaveArticle = async () => {
      if(state.video.title === ""){
        Toast('视频标题不能为空');
      }else {
        Toast.loading({
          message: '正在保存...',
          forbidClick:true,
          duration:0,
        });
        let p = {
          vdId:state.vdId,
          name:state.video.title,
          parseUrl:state.reprintUrl,
          originalLikes:Math.floor(Math.random()*(999999-1000))+1000,
          forwardingNumber:Math.floor(Math.random()*(999999-1000))+1000
        }
        addVideoByParseUrl(p).then(res=>{
          if (res){
            Toast.clear()
            router.replace({
              path: `/video/videoDetail/${state.vdId}`
            });
          }
        })
      }

    };
    const handleBlack = () => {
      router.back();
    };
    const handleGenerate= async ()=>{
      state.showFinish = false
      Toast.loading({
        message: '解析中...',
        // forbidClick:true,
        duration:0,
      });
      parseDouYinVideoUrl({
        parseUrl:state.reprintUrl
      }).then(res=>{
        console.log("解析视频==============",res)
        if (res){
          Toast.clear()
          state.vdId = res.vdId
          state.showFinish = true
          state.video.title = res.name
          Toast.success('解析完成');
        }else {
          Toast.clear()
        }
      })
      setTimeout(()=>{
        Toast.clear()
      },1000)
    }
    return {
      ...toRefs(state),
      handleBlack,
      handleSaveArticle,
      handleGenerate,
      handleOpenBrowser,
    };
  },
};
</script>
<style lang="scss">
.reprint-article-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
  //遮罩层
  //遮罩层
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
        }
      }
    }
  }

  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .reprint{
      padding: 16px;
      > span {
        text-align: left;
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      .supper-platform{
        padding-top: 10px;
        text-align: left;
        font-size: 14px;
        span{
          width: 50px;
          color: #289fff;
        }
      }
      .url-info{
        display: flex;
        align-items: center;
        >span{
          text-align: right;
        }
      }
    }
    .article_info {
      padding: 17px;
      text-align: left;
      border-radius: 5px;
      > span {
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      > span:nth-child(3) {
        margin-top: 30px;
      }
    }
    .article_editor {
      img{
        width: 100%;
      }
      text-align: left;
      font-size: 14px;
    }
    .star-and-con {
      padding: 0 16px;

      .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
    }
    .is-show-card{

      padding: 0 16px;
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 600;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

    }
    .button_confirm {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      > div {
        width: 160px;
        height: 44px;
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        line-height: 44px;
        background-color: #bfbfbf;
      }
      .button_confirm_save {
        background: linear-gradient(0deg, #ff6e01, #ff6e01);
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f1f3f6;
      }
    }
  }
}
</style>
